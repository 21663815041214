<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title class="pb-0 primary--text"
        >User Registration Form</v-card-title
      >
      <v-form ref="userForm">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-subheader class="pa-1 primary--text">First Name</v-subheader>
              <v-text-field
                v-model="userData.firstName"
                :rules="[
                  () => !!userData.firstName || 'This field is required',
                ]"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-subheader class="pa-1 primary--text">Last Name</v-subheader>
              <v-text-field
                v-model="userData.lastName"
                :rules="[() => !!userData.lastName || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text">Username</v-subheader>
              <v-text-field
                v-model="userData.name"
                :rules="[() => !!userData.name || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text">Designation</v-subheader>
              <v-text-field
                v-model="userData.designation"
                :rules="[
                  () => !!userData.designation || 'This field is required',
                ]"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text">Email</v-subheader>
              <v-text-field
                v-model="userData.email"
                :rules="[() => !!userData.email || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text">Mobile Phone</v-subheader>
              <v-text-field
                v-model="userData.phone"
                required
                outlined
                dense
                autocomplete="off"
                @keypress="isNumber($event)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text">Password</v-subheader>
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="userData.password"
                :rules="[() => !!userData.password || 'This field is required']"
                required
                outlined
                dense
                autocomplete="nope"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text"
                >Confirm Password</v-subheader
              >
              <v-text-field
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="userData.confirmPword"
                :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                required
                outlined
                dense
                autocomplete="off"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 primary--text">Roles</v-subheader>
              <v-select
                v-model="userData.roles"
                :rules="[() => !!userData.roles || 'This field is required']"
                required
                outlined
                dense
                autocomplete="off"
                :items="userRoles"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>

            <v-subheader class="pa-1 primary--text"></v-subheader>
            <v-col cols="12" md="6" class="pb-5 pt-0">
              <!-- <v-checkbox
                class="mr-4"
                v-model="userData.dataValidation"
                label="Data Validation"
                v-bind:false-value="0"
                v-bind:true-value="1"
              >
              </v-checkbox> -->

              <v-checkbox
                class="mr-4"
                v-model="userData.alert"
                label="Alert"
                v-bind:false-value="0"
                v-bind:true-value="1"
              >
              </v-checkbox>
            </v-col>

            <v-col cols="12" md="12" class="pb-2 pt-0">
              <v-btn class="mr-4 primary" @click="postUserData"> submit </v-btn>
            </v-col>
          </v-row>

          <v-dialog v-model="successDialog" persistent max-width="500px">
            <v-card>
              <v-card-title class="justify-center text-h5 success white--text">
                Successful Add New User
                </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="justify-center success--text"
                  text
                  @click="close"
                  >Close</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    successDialog: false,
    showConfirmPassword: false,
    showPassword: false,
    userData: {
      firstName: "",
      lastName: "",
      name: "",
      designation: "",
      email: "",
      confirmPword: "",
      password: "",
      phone: "",
      roles: "",
      dataValidation: "",
      alert: "",
    },
    confirmPasswordRules: [(v) => !!v || "Password is required"],

    userRoles: [
      { text: "admin" },
      { text: "operation" },
      { text: "qaqc" },
      { text: "general" }
    ],
    dataValidation: 0,
    alert: 0,
  }),

  methods: {

    isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    },

    postUserData() {
      const payload = {
        firstname: this.userData.firstName,
        lastname: this.userData.lastName,
        name: this.userData.name,
        designation: this.userData.designation,
        email: this.userData.email,
        password: this.userData.password,
        phoneNo: this.userData.phone,
        role: this.userData.roles,
        dataValidationStatus: this.userData.dataValidation,
        alert1: this.userData.alert,
      };

      if (payload.firstname != "" && payload.lastname != ""
      && payload.name != "" && payload.designation != "" && payload.email != "" &&
      payload.password != "" && payload.phoneNo != "" && payload.role != "") {
        axios.post(`${process.env.VUE_APP_API_URL}/users/add`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.successDialog = true;
          this.$refs.userForm.reset();
        })
        .catch((error) => {
          console.log(error);
        });
      }
      else{
        let self = this
        setTimeout(function () {
            if (self.$refs.userForm.validate()){
            //other codes
            alert('submitted')
            }  
        })
      }

      
    },

    close() {
      this.successDialog = false;
    },
  },

  computed: {
    passwordConfirmationRule() {
      return () =>
        this.userData.password === this.userData.confirmPword ||
        "Password must match";
    },
  },

  mounted() {},
};
</script>

 <style lang="scss" scoped>
.v-subheader {
  height: 30px;
}
</style>